import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components"

export default class blogPost extends Component {
  render() {
    const data = this.props.data.contentfulBlogs;

    const siteurl = this.props.data.contentfulSiteInformation.siteUrl + "/";
    const twitterhandle = this.props.data.contentfulSiteInformation
      .twitterHandle;
    const socialConfigss = {
      site: {
        siteMetadata: { siteurl, twitterhandle }
      },
      title: data.title,
      slug: data.slug
    };

    return (
      <Layout>
        <SEO
          title={data.title}
          keywords={[
            `Dijana Pavlovic`,
            `Ux/Ui Designer`,
            `Designer`,
            `${data.title}`
          ]}
        />
        <div className="site-container blog-post">
          <div className="container">
            {data.featureImage ? (
              <Img
                className="feature-img"
                fluid={data.featureImage}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            ) : (
              <div className="no-image"></div>
            )}

            <div className="details">
              <h1 className="title">{data.title}</h1>
              <HtmlContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.description.childMarkdownRemark.html
                  }}
                />
              </HtmlContent>
            </div>
          </div>
          <div className="see-more">
            <Link to="/projekt">
              <span>Se alla projekt</span>
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

const HtmlContent = styled.table`
table {
  width: 100%
}
thead {
  display:none
}

td{
  margin: 1rem auto;
}
`

export const pageQuery = graphql`
  query SinglePostQuery($slug: String!) {
    contentfulBlogs(slug: { eq: $slug }) {
      id
      title
      slug
      featureImage {
        src: url
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      createdAt
    }
    contentfulSiteInformation {
      siteUrl
      twitterHandle
    }
  }
`;
